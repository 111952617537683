@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-grow {
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
}

footer {
  background-color: #f5f5f5;
  padding: 20px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.container {
  max-width: 900px;
}

#boodschap {
  font-size: 1.2em;
  font-weight: bold;

  margin-left: auto;
  margin-right: auto;
  width: 80%;
  text-align: center;
}

.startFile {
  color: rgb(0, 85, 38);
  font-weight: bold;
}

.current_path {
  background-color: rgb(246, 253, 178);
}


.current_folder_items {
  position: relative;
}

.current_folder {
  color:rgba(95, 2, 2, 0.795); 
}

.current_folder_items::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 2px;
  background-color: rgb(177, 113, 17); /* Donkerdere kleur voor de lijn */
}

.root_folder {
  content: '';
  border-left: 6px solid rgb(177, 113, 17);
}